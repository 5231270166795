<template>
	<v-app>
		<v-card>
			<v-card-title>ユーザを招待</v-card-title>
			<v-card-text>
				<v-label> お名前 </v-label>
				<v-text-field outlined v-model="name"> </v-text-field>

				<v-label> メールアドレス </v-label>
				<v-text-field v-model="email" outlined></v-text-field>

				<v-label> 権限 </v-label>
				<v-select v-model="role" :items="roles" outlined></v-select>

				<v-label> プロジェクト: </v-label>
				<v-select v-model="projectId" :items="projects" disabled outlined></v-select>
			</v-card-text>
			<v-card-actions>
				<v-btn depressed @click="save" class="save">このプロジェクトに招待する</v-btn>
			</v-card-actions>
		</v-card>
		<v-footer>
			©2021 <a href="https://www.vegetalia.co.jp/">vegetalia</a>, inc.
		</v-footer>
	</v-app>
</template>

<script>
import axios from "axios";
import md5 from "js-md5";

export default {
	data: () => ({
		name: "",
		email: "",
		role: "normal",
		roles: [{text: "通常", value: "normal"}, {text: "管理者", value: "admin"}],
		projectId: "",
		projects: [],
	}),
	created() {
		axios.defaults.headers.common["Authorization"] =
			"Bearer " + this.$store.state.auth.data.token;
	},
	mounted() {
		if (this.$route.params.id && this.$route.params.name) {
			this.projectId = this.$route.params.id;
			this.projects = [{text: this.$route.params.name, value: this.projectId}];
		} else {
			this.$router.push({ name: "AdminProject" }).catch(() => {});
		}
	},
	methods: {
		save() {
			if (this.name && this.email) {
				var reg = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/;
				if (!reg.test(this.email)) {
					alert("メールアドレスが不正です");
					return;
				}
				this.showLoading();
				const params = {
					uid: this.$store.state.user.data.id,
					name: this.name,
					email: this.email,
					role: this.role,
					projectId: this.projectId,
				};
				axios
					.post(
						"https://" +
							this.config.defaultOriginString +
							"/api/admin-update-user",
						params
					)
					.then((res) => {
						this.hideLoading();
						// 成功した場合
						if (res && res.data && res.data.status == "success") {
							console.log("Success: " + JSON.stringify(res.data));
							this.$router.push({ name: "AdminProject" }).catch(() => {});
						} else {
							console.log("Fail: " + JSON.stringify(res));
							alert("メールアドレスが既に使用されているか、もしくは有効ではありません");
						}
					})
					.catch((error) => {
						this.hideLoading();
						console.log(error);
						alert("不明なエラーが発生しました");
					});
			} else {
				alert("お名前とメールアドレスは必須項目です");
			}
		},
		showLoading: function () {
			this.$emit("loadShowHide", true);
		},
		hideLoading: function () {
			this.$emit("loadShowHide", false);
		},
	},
};
</script>
